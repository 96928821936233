import { CustomerProperties } from '@api/customer'
import {
  ConditionKey,
  EventKey,
  EventPropertyOption,
  IntegrationProviderId,
  ListRule,
  PropertyType
} from '@api/types'
import { startCase } from 'lodash'
import { INTEGRATION_PROVIDERS } from './integrations'
import { AvailableCustomerEvent } from '@/api/customer-event'

export const EMPTY_OPERATOR = {
  op: '',
  value: undefined
}
export const EMPTY_COUNT_OPERATOR = {
  op: '$exists',
  value: 1
}
export const EMPTY_CONDITION = {
  key: '',
  type: '',
  operators: [EMPTY_OPERATOR]
}
export const EMPTY_EVENT = {
  event: '',
  type: '',
  count: EMPTY_COUNT_OPERATOR,
  conditions: []
}
export const EMPTY_CONDITION_RULE: ListRule = {
  type: 'condition',
  rule: EMPTY_CONDITION
}
export const EMPTY_EVENT_RULE: ListRule = {
  type: 'event',
  rule: EMPTY_EVENT
}
export const EMPTY_INITIAL_VALUES = {
  name: '',
  rules: [EMPTY_CONDITION_RULE]
}
export const EMPTY_PREVIEW = {
  count: 0,
  members: []
}
export const RULE_TYPES = [
  {
    label: 'Properties about someone',
    value: 'condition'
  },
  {
    label: 'What someone has done',
    value: 'event'
  }
  // {
  //   name: 'If someone is or is not in a list',
  //   id: 'list'
  // }
]

export const EVENT_COUNT_OPERATORS = [
  {
    label: 'At least once',
    value: '$exists'
  },
  {
    label: 'Exactly',
    value: '$eq'
  },
  // {
  //   label: 'Greater than',
  //   value: '$gt'
  // },
  {
    label: 'Greater than or equal to',
    value: '$gte'
  },
  // {
  //   label: 'Less than',
  //   value: '$lt'
  // },
  // {
  //   label: 'Less than or equal to',
  //   value: '$lte'
  // }
]

export const STRING_OPERATORS = [
  {
    label: 'Exists',
    value: '$exists'
  },
  {
    label: 'Contains',
    value: '$regex'
  },
  {
    label: 'Equals',
    value: '$eq'
  },
  {
    label: 'Does not equal',
    value: '$ne'
  },
  {
    label: 'Includes any',
    value: '$in'
  },
  {
    label: 'Does not include any',
    value: '$nin'
  }
]
export const NUMBER_OPERATORS = [
  {
    label: 'Equals',
    value: '$eq'
  },
  {
    label: 'Does not equal',
    value: '$ne'
  },
  {
    label: 'Less than',
    value: '$lt'
  },
  {
    label: 'Less than or equal to',
    value: '$lte'
  },
  {
    label: 'Greater than',
    value: '$gt'
  },
  {
    label: 'Greater than or equal to',
    value: '$gte'
  }
]
export const CURRENCY_OPERATORS = [
  {
    label: 'Less than',
    value: '$lt'
  },
  {
    label: 'Less than or equal to',
    value: '$lte'
  },
  {
    label: 'Greater than',
    value: '$gt'
  },
  {
    label: 'Greater than or equal to',
    value: '$gte'
  }
]
export const DATE_OPERATORS = [
  {
    label: 'In the last',
    value: '$last'
  },
  {
    label: 'In the next',
    value: '$next'
  },
  {
    label: 'Before',
    value: '$lte'
  },
  {
    label: 'After',
    value: '$gte'
  }
]
export const ORDINAL_OPERATORS = [
  {
    label: 'In the next',
    value: '$next'
  },
  {
    label: 'In the last',
    value: '$last'
  }
]
export const BOOLEAN_OPERATORS = [
  {
    label: 'Equals',
    value: '$eq'
  },
  {
    label: 'Does not equal',
    value: '$ne'
  }
]
export const BOOLEAN_VALUES = [
  {
    label: 'True',
    value: 'true'
  },
  {
    label: 'False',
    value: 'false'
  }
]
export const ENUM_OPERATORS = [
  {
    label: 'Equals',
    value: '$eq'
  },
  {
    label: 'Does not equal',
    value: '$ne'
  }
]
export const ARRAY_OPERATORS = [
  {
    label: 'Includes any',
    value: '$in'
  },
  {
    label: 'Does not include any',
    value: '$nin'
  }
]
export const ENUM_VALUES: { [key: string]: any[] } = {
  duration: [
    {
      label: 'Hours',
      value: 'hours'
    },
    {
      label: 'Days',
      value: 'days'
    },
    {
      label: 'Weeks',
      value: 'weeks'
    },
    {
      label: 'Months',
      value: 'months'
    },
    {
      label: 'Years',
      value: 'years'
    }
  ],
  'properties.mindbody.Gender': [
    {
      name: 'Male',
      id: 'Male'
    },
    {
      name: 'Female',
      id: 'Female'
    }
  ],
  'properties.mindbody.Status': [
    {
      name: 'Active',
      id: 'Active'
    },
    {
      name: 'Non member',
      id: 'Non-Member'
    },
    {
      name: 'Expired',
      id: 'Expired'
    },
    {
      name: 'Declined',
      id: 'Declined'
    },
    {
      name: 'Suspended',
      id: 'Suspended'
    },
    {
      name: 'Terminated',
      id: 'Terminated'
    }
  ],
  'properties.mindbody.MembershipStats.AutopayStatus': [
    {
      name: 'Active',
      id: 'Active'
    },
    {
      name: 'Inactive',
      id: 'Inactive'
    },
    {
      name: 'Suspended',
      id: 'Suspended'
    }
  ]
}
export const OPERATORS: { [key: string]: any[] } = {
  string: STRING_OPERATORS,
  number: NUMBER_OPERATORS,
  currency: CURRENCY_OPERATORS,
  date: DATE_OPERATORS,
  ordinal: ORDINAL_OPERATORS,
  boolean: BOOLEAN_OPERATORS,
  enum: ENUM_OPERATORS,
  array: ARRAY_OPERATORS
}
export const OPERATOR_VALUES = {}

export type GroupedOption<T> = {
  label: string
  options: T[]
}
export function customerPropertyOptions(props: CustomerProperties): {
  options: ConditionKey[]
  groupedOptions: GroupedOption<ConditionKey>[]
} {
  function parseLabel(key: string): string {
    let label: string = key
    label = label.replace('properties.tablelist.stats', '')
    label = label.replace('properties.tablelist.socialProfiles', '')
    label = label.replace('properties.mindbody.VisitStats', '')
    label = label.replace('properties.mariana_tek', '')
    INTEGRATION_PROVIDERS.forEach((integration) => {
      label = label.replace(`properties.${integration.id.replace('-', '')}.`, '')
    })
    label = label.replace(`properties.`, '')
    return startCase(label)
  }
  function parseSource(key: string): IntegrationProviderId {
    if (key.includes('properties.mindbody')) {
      return 'mindbody'
    } else if (key.includes('properties.activecampaign')) {
      return 'activecampaign'
    } else if (key.includes('properties.tablelist')) {
      return 'tablelist'
    } else if (key.includes('properties.mariana_tek')) {
      return 'mariana-tek'
    } else {
      return 'helios'
    }
  }
  function groupBySource(conditionKeys: ConditionKey[]): GroupedOption<ConditionKey>[] {
    return Object.values(
      conditionKeys.reduce(
        (grouped, key) => {
          ;(grouped[key.source] = grouped[key.source] || {
            label: key.source,
            options: []
          }).options.push(key)
          return grouped
        },
        {} as Record<string, GroupedOption<ConditionKey>>
      )
    )
  }
  const options: ConditionKey[] = []
  for (const customerKey of Object.keys(props.properties)) {
    options.push({
      label: parseLabel(customerKey),
      description: '',
      value: customerKey,
      source: parseSource(customerKey),
      type: props.properties[customerKey].type
    })
  }
  const groupedOptions = groupBySource(options)
  return {
    groupedOptions,
    options
  }
}

export function customerEventOptions(availableCustomerEvents: AvailableCustomerEvent[]): {
  options: EventKey[]
  groupedOptions: GroupedOption<EventKey>[]
} {
  function groupBySource(keys: EventKey[]): GroupedOption<EventKey>[] {
    return Object.values(
      keys.reduce(
        (grouped, key) => {
          ;(grouped[key.source] = grouped[key.source] || {
            label: key.source,
            options: []
          }).options.push(key)
          return grouped
        },
        {} as Record<string, GroupedOption<EventKey>>
      )
    )
  }
  const options: EventKey[] = []
  availableCustomerEvents.forEach((eventGroup: any) => {
    eventGroup.events.forEach((eventKey: any) => {
      options.push({
        label: startCase(eventKey),
        description: '',
        value: eventKey,
        source: eventGroup.type,
        type: eventGroup.type
      })
    })
  })

  const groupedOptions = groupBySource(options)
  return {
    groupedOptions,
    options
  }
}

export function customerEventPropertyOptions(props: { properties: Record<string, { type: PropertyType }> }): {
  options: EventPropertyOption[]
} {
  function parseLabel(key: string): string {
    return startCase(key.replace(`properties.`, ''))
  }
  const options: EventPropertyOption[] = []
  for (const propertyKey of Object.keys(props.properties)) {
    options.push({
      label: parseLabel(propertyKey),
      value: propertyKey,
      type: props.properties[propertyKey].type
    })
  }
  return {
    options
  }
}

export function customerEventPropertyValueOptions(props: { values: 'string'[] }): {
  options: EventPropertyOption[]
} {
  const options: EventPropertyOption[] = props.values.map((value) => {
    return {
      label: value,
      value: value,
      type: 'string'
    }
  })
  return {
    options
  }
}

export const ORDINAL_PROPERTIES = new Set([
  'properties.tablelist.birthdayOrdinal',
  'properties.mariana_tek.birth_date_ordinal'
])
