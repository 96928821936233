import { IntegrationProvider, SegmentTemplateType } from '@api/types'

export const INTEGRATION_PROVIDERS: IntegrationProvider[] = [
  {
    name: 'Hubspot',
    category: 'CRM',
    description: 'Sync contacts and leads with your Hubspot account.',
    logo: '/images/integrations/hubspot/icon.svg',
    url: 'https://www.hubspot.com',
    id: 'hubspot',
    features: [
      'Imports all existing contacts from Hubspot into Helios',
      'Imports and updates leads from Hubspot in real time',
      'Report conversions from Helios conversations',
    ],
    requirements: ['Valid and current Hubspot account'],
  },
  {
    name: 'Square',
    category: 'POS',
    description: 'Import and message your Square contacts.',
    logo: '/images/integrations/square/icon.png',
    url: 'https://squareup.com',
    id: 'square',
    features: [
      'Imports all existing clients from Square into Helios',
      'Imports and updates clients from Square in real time',
      'Report conversions from Helios conversations',
    ],
    requirements: ['Valid and current Square account'],
  },
  {
    name: 'Shopify',
    category: 'eCommerce',
    description: 'Import and message your Shopify contacts.',
    logo: '/images/integrations/shopify/icon.png',
    url: 'https://www.shopify.com/',
    id: 'shopify',
    features: [
      'Imports all existing clients from Shopify into Helios',
      'Imports and updates clients from Shopify in real time',
      'Report conversions from Helios conversations',
    ],
    requirements: ['Valid and current Shopify account'],
  },
  {
    name: 'Mariana Tek',
    category: 'CRM',
    description: 'Import and message your Xplor Mariana Tek contacts.',
    logo: '/images/integrations/mariana-tek/icon.png',
    url: 'https://www.marianatek.com/',
    id: 'mariana-tek',
    features: [
      'Imports all existing clients from Mariana Tek into Helios',
      'Imports and updates clients from Mariana Tek in real time',
      'Report conversions from Helios conversations',
    ],
    requirements: ['Valid and current Mariana Tek account'],
  },
  {
    name: 'Mindbody',
    category: 'CRM',
    description: 'Import your clients from Mindbody every hour.',
    logo: 'https://i.ibb.co/Wp6T55P/mindbody.jpg',
    url: 'https://www.mindbodyonline.com',
    id: 'mindbody',
    features: [
      'Imports all existing clients from Mindbody into Helios',
      'Imports and updates clients from Mindbody in real time',
      'Report conversions from Helios conversations',
    ],
    requirements: ['Valid and current Mindbody account', 'Valid and active Mindbody SiteID'],
  },
  {
    name: 'ActiveCampaign',
    category: 'CRM',
    description: 'Import and message your ActiveCampaign contacts.',
    logo: '/images/active-campaign-glyph.png',
    url: 'https://www.activecampaign.com',
    id: 'active-campaign',
    features: [
      'Imports all existing clients from ActiveCampaign into Helios',
      'Imports new clients from ActiveCampaign in real time',
    ],
    requirements: ['Valid and current ActiveCampaign account'],
  },
  {
    name: 'Tablelist',
    category: 'CRM',
    description: 'Import and message your Tablelist clients.',
    logo: 'https://i.ibb.co/Pm2RsJ9/tablelist.png',
    url: 'https://www.tablelistpro.com',
    id: 'tablelist',
    features: [
      'Imports all existing clients from Tablelist into Helios',
      'Imports new clients from Tablelist in real time',
      'Report conversions from Helios conversations',
    ],
    requirements: ['Valid and current TablelistPro account'],
  },
  {
    name: 'Webhook',
    category: 'CRM',
    description: 'Import contacts from a JavaScript SDK or Backend API.',
    logo: 'https://i.ibb.co/DKMjnht/webhooks-logo-png-transparent.png',
    url: 'https://sendhelios.com/help-center',
    id: 'webhook',
    features: [
      'Import contacts using a client side JavaScript SDK',
      'Import contacts from a 3rd party webhook provider',
    ],
    requirements: ['Ability to send HTTP requests'],
  },
  {
    name: 'Helios',
    category: 'hidden',
    description: '',
    logo: '/images/integrations/helios/icon.png',
    url: 'https://sendhelios.com/help-center',
    id: 'helios',
    features: [],
    requirements: [],
  },
]
const MINDBODY_INTEGRATION_PROVIDER = <IntegrationProvider>(
  INTEGRATION_PROVIDERS.find((i) => i.id === 'mindbody')
)
const MARIANATEK_INTEGRATION_PROVIDER = <IntegrationProvider>(
  INTEGRATION_PROVIDERS.find((i) => i.id === 'mariana-tek')
)
const TABLELIST_INTEGRATION_PROVIDER = <IntegrationProvider>(
  INTEGRATION_PROVIDERS.find((i) => i.id === 'tablelist')
)
const WEBHOOK_INTEGRATION_PROVIDER = <IntegrationProvider>(
  INTEGRATION_PROVIDERS.find((i) => i.id === 'webhook')
)
const HELIOS_INTEGRATION_PROVIDER = <IntegrationProvider>(
  INTEGRATION_PROVIDERS.find((i) => i.id === 'helios')
)

export const INTEGRATION_JOB_TYPES: any = Object.freeze({
  hubspot_client_ingest: 'Import Clients',
  shopify_client_ingest: 'Import Clients',
  mindbody_client_ingest: 'Import Clients',
  mariana_tek_client_ingest: 'Import Clients',
  mariana_tek_membership_ingest: 'Import Memberships',
})

export const MINDBODY_SEGMENT_TEMPLATES: SegmentTemplateType[] = [
  {
    id: 'recent-first-visit',
    title: 'Recent first visit',
    description: 'Mindbody clients with a recent first visit.',
    integrationProvider: MINDBODY_INTEGRATION_PROVIDER,
    initialValues: {
      name: 'Recent first visit',
      type: 'dynamic',
      rules: [
        {
          type: 'condition',
          rule: {
            key: 'properties.mindbody.VisitStats.FirstVisitDate',
            type: 'date',
            operators: [
              {
                op: '$last',
                value: {
                  $now: {
                    duration_unit: 'months',
                    duration_value: '7',
                  },
                },
              },
            ],
          },
        },
      ],
      conditions: [],
      events: [],
    },
  },
  {
    id: 'single-visit',
    title: 'Took first class but haven’t returned',
    description: 'Mindbody clients with only one visit.',
    integrationProvider: MINDBODY_INTEGRATION_PROVIDER,
    initialValues: {
      name: 'Took first class but haven’t returned',
      type: 'dynamic',
      conditions: [
        {
          condition: 'properties',
          key: 'properties.mindbody.VisitStats.TotalPastVisits',
          type: 'number',
          operators: [
            {
              op: '$eq',
              value: 1,
            },
          ],
        },
        {
          condition: 'properties',
          key: 'properties.mindbody.VisitStats.TotalFutureVisits',
          type: 'number',
          operators: [
            {
              op: '$eq',
              value: 0,
            },
          ],
        },
      ],
      events: [],
      rules: [],
    },
  },
  {
    id: 'non-members',
    title: 'Non members',
    description: 'Mindbody clients with status of non-member.',
    integrationProvider: MINDBODY_INTEGRATION_PROVIDER,
    initialValues: {
      name: 'Non members',
      type: 'dynamic',
      conditions: [
        {
          condition: 'properties',
          key: 'properties.mindbody.Status',
          type: 'enum',
          operators: [
            {
              op: '$eq',
              value: 'Non-Member',
            },
          ],
        },
      ],
      events: [],
      rules: [],
    },
  },
  {
    id: 'churned-memberships',
    title: 'Churned members',
    description: 'Mindbody clients with a recently ended membership.',
    integrationProvider: MINDBODY_INTEGRATION_PROVIDER,
    initialValues: {
      name: 'Churned members',
      type: 'dynamic',
      conditions: [
        {
          condition: 'properties',
          key: 'properties.mindbody.MembershipStats.EndDate',
          type: 'date',
          operators: [
            {
              op: '$last',
              value: {
                $now: {
                  duration_unit: 'days',
                  duration_value: '30',
                },
              },
            },
          ],
        },
        {
          condition: 'properties',
          key: 'properties.mindbody.Status',
          type: 'enum',
          operators: [
            {
              op: '$ne',
              value: 'Active',
            },
          ],
        },
      ],
      events: [],
      rules: [],
    },
  },
  {
    id: 'active-members',
    title: 'Active members',
    description: 'Mindbody clients with status of active.',
    integrationProvider: MINDBODY_INTEGRATION_PROVIDER,
    initialValues: {
      name: 'Active members',
      type: 'dynamic',
      conditions: [
        {
          condition: 'properties',
          key: 'properties.mindbody.Status',
          type: 'enum',
          operators: [
            {
              op: '$eq',
              value: 'Active',
            },
          ],
        },
      ],
      events: [],
      rules: [],
    },
  },
  {
    id: 'active-members-not-autopay',
    title: 'Active members without autopay',
    description: 'Mindbody clients with an active membership not enrolled in autopay.',
    integrationProvider: MINDBODY_INTEGRATION_PROVIDER,
    initialValues: {
      name: 'Active members not enrolled in autopay',
      type: 'dynamic',
      conditions: [
        {
          condition: 'properties',
          key: 'properties.mindbody.Status',
          type: 'enum',
          operators: [
            {
              op: '$eq',
              value: 'Active',
            },
          ],
        },
        {
          condition: 'properties',
          key: 'properties.mindbody.MembershipStats.AutopayStatus',
          type: 'enum',
          operators: [
            {
              op: '$ne',
              value: 'Active',
            },
          ],
        },
      ],
      events: [],
      rules: [],
    },
  },
  {
    id: 'expiring-memberships',
    title: 'Memberships ending soon',
    description: 'Mindbody clients with an upcoming membership end date.',
    integrationProvider: MINDBODY_INTEGRATION_PROVIDER,
    initialValues: {
      name: 'Memberships ending soon',
      type: 'dynamic',
      conditions: [
        {
          condition: 'properties',
          key: 'properties.mindbody.MembershipStats.EndDate',
          type: 'date',
          operators: [
            {
              op: '$next',
              value: {
                $now: {
                  duration_unit: 'days',
                  duration_value: '30',
                },
              },
            },
          ],
        },
      ],
      events: [],
      rules: [],
    },
  },
]
export const TABLELIST_SEGMENT_TEMPLATES: SegmentTemplateType[] = [
  {
    id: 'upcoming-birthday',
    title: 'Upcoming Birthday',
    description: 'Tablelist customers with a birthday in the next 2 weeks.',
    integrationProvider: TABLELIST_INTEGRATION_PROVIDER,
    initialValues: {
      name: 'Has birthday in next 2 weeks',
      type: 'dynamic',
      rules: [
        {
          type: 'condition',
          rule: {
            key: 'properties.tablelist.birthdayOrdinal',
            type: 'number',
            operators: [
              {
                op: '$next',
                value: {
                  $lte: {
                    $ordinal: 14,
                  },
                },
              },
            ],
          },
        },
      ],
      events: [],
      conditions: [],
    },
  },
  {
    id: 'total-spend',
    title: 'Total Spend Amount',
    description: 'Tablelist customers with total spend amount.',
    integrationProvider: TABLELIST_INTEGRATION_PROVIDER,
    initialValues: {
      name: 'Total Spend greater than {$X}',
      type: 'dynamic',
      rules: [
        {
          type: 'condition',
          rule: {
            key: 'properties.tablelist.stats.totalSpend',
            type: 'number',
            operators: [
              {
                op: '$gte',
                value: 0,
              },
            ],
          },
        },
      ],
      events: [],
      conditions: [],
    },
  },
]

export const HELIOS_SEGMENT_TEMPLATES: SegmentTemplateType[] = [
  {
    id: 'all-contacts',
    title: 'All Contacts',
    description: 'Send a message to all of your contacts.',
    integrationProvider: HELIOS_INTEGRATION_PROVIDER,
    initialValues: {
      name: 'All contacts',
      type: 'dynamic',
      rules: [
        {
          type: 'condition',
          rule: {
            key: 'phoneNumber',
            type: 'string',
            operators: [
              {
                op: '$exists',
                value: 'true',
              },
            ],
          },
        },
      ],
      events: [],
      conditions: [],
    },
  },
]

export const SEGMENT_TEMPLATES: Record<any, SegmentTemplateType[]> = {
  helios: HELIOS_SEGMENT_TEMPLATES,
  mindbody: MINDBODY_SEGMENT_TEMPLATES,
  tablelist: TABLELIST_SEGMENT_TEMPLATES,
  'active-campaign': [],
  'mariana-tek': [],
  shopify: [],
  hubspot: [],
  webhook: [],
}
